
import axios from "axios";

const client = axios.create({
  //baseURL: "https://localhost:7222" ,//Dev
  baseURL: "http://localhost:801" ,//UAT
  //baseURL: "https://api.anandhotel.in" ,//Production
  timeout:1000,
  headers: { 
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }
});

export class ClientService{
    constructor(){}
    async get(url){
        try{
                const response=await client.get(url);
                return response.data;
        }
        catch(error)
        {
                return [];
        }
    }
    async post(url,data){
        try{
                const response=await client.post(url,data);
                return response.data;
        }
        catch(error)
        {
                return [];
        }
    }
    async put(url,data){
        try{
                const response=await client.put(url,data);
                return response.data;
        }
        catch(error)
        {
                return [];
        }
    }
    async delete(url){
        try{
                const response=await client.delete(url);
                return response.data;
        }
        catch(error)
        {
                return [];
        }
    }
}