

import React, { useCallback, useMemo, useRef, useState } from 'react';
//import { render } from 'react-dom';
//import Table from 'react-bootstrap/Table';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { propTypes } from 'react-bootstrap/esm/Image';
import { ClientService } from '../../services/service';

export default class OrderCreate extends React.Component{
    constructor(props) {
        super(props);
        this.service=new ClientService();
        this.state={          
          IsOther:false,
            rowData:[],
            columnDefs:[              
                { field: 'ProductId', sortable: false,hide:true},
                { field: 'code' ,width:80,sortable: true},
                { field: 'Name' ,headerName:'Item',width:200, sortable: true},
                { field: 'UnitPrice' ,headerName:'Half', width:100,sortable: true},
                { field: 'UnitPriceFull' , headerName:'Full',width:130,sortable: true}],
            categories:[{stringmapid:"0",name:"Other"}],
            sittingtables:[{stringmapid:"0",name:"Other"}],
            productsizes:[{stringmapid:"0",name:"Other"}],
            order:{
              LeadId:"",
              ProductId: "",
                SizeId: null,                
                Qty: 0,
                Rate:0,
                Remark:"",
            }
            

        }

        this.handleCategoryChange=this.handleCategoryChange.bind(this);
        
        this.handleProductSizeChange=this.handleProductSizeChange.bind(this);
        this.handleQtyChange=this.handleQtyChange.bind(this);

        this.handleRateChange=this.handleRateChange.bind(this);
        this.handleRemarkChange=this.handleRemarkChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);

        this.onSelectionChanged=this.onSelectionChanged.bind(this);
        this.GetProduct=this.GetProduct.bind(this);
        this.GetProductCategories=this.GetProductCategories.bind(this);
        
        this.GetSizes=this.GetSizes.bind(this);
      this.handleProductCodeChange=this.handleProductCodeChange.bind(this);    
    }
    handleProductCodeChange(e)
    {
     // alert(e.target.value);
     if(isNaN(e.target.value)){
        let filter="StateCode eq true and  contains(name,'"+e.target.value+"')";
        this.GetProduct(filter);
     }
     else
     {

        let filter="StateCode eq true and  code eq "+ e.target.value;
       // alert(filter);
        this.GetProduct(filter);
     }
    }

    componentDidMount(){
        //alert("Mounted");
        this.GetProductCategories();
        //this.GetTables();
        this.GetSizes();
    }
   
    GetProductCategories()
    {
        let url="api/stringmap?$select=stringmapid,name&$filter=statecode eq true and type eq 'menu' and objectname eq 'Product' and attribute eq 'CategoryId'&$orderby=displayorder";
        this.service.get(url)
        .then((responsedata)=>{
          debugger;
          this.setState({categories:responsedata});
            
        })
        .catch((err) => { 
          //console.log(this.state.rowData);
        });
    }
    
    GetSizes()
    {
        let url="api/stringmap?$select=stringmapid,name&$filter=statecode eq true and type eq 'productsize' and objectname eq 'order' and attribute eq 'Size'&$orderby=displayorder";
        this.service.get(url)
        .then((responsedata)=>{
          debugger;
          this.setState({productsizes:responsedata});
            
        })
        .catch((err) => { 
          //console.log(this.state.rowData);
        });
    }
    handleCategoryChange = (event) => {
        //const name = event.target.name;
        //const value = event.target.value;
        let filter="StateCode eq true and categoryid eq "+event.target.value;
        this.GetProduct(filter);
//        alert(value);
       // setInputs(values => ({...values, [name]: value}));
        //setMyCar(event.target.value);
      }
      
      handleProductSizeChange = (event) => {
        const value = event.target.value;
        //alert(value);
        let curorder=this.state.order;
        curorder.SizeId=value;        
        this.setState({order:curorder});
      }
      handleQtyChange = (event) => {
        //const name = event.target.name;
        
        const value = event.target.value;
        if(!isNaN(value)){
          let curorder=this.state.order;
          curorder.Qty=Number(value);        
          this.setState({order:curorder});
        }
        //alert("Qty: "+value);
        
      }

      handleRateChange = (event) => {
        const value = event.target.value;
        let curorder=this.state.order;
        curorder.Rate=value;        
        this.setState({order:curorder});
      }

      handleRemarkChange = (event) => {
        const value = event.target.value;
        let curorder=this.state.order;
        curorder.Remark=value;        
        this.setState({order:curorder});
      }

    handleSubmit = (event) => {
        event.preventDefault();
        if(this.props.lead.LeadId===null || this.props.lead.LeadId==="" || this.props.lead.LeadId==='undefined'){
            alert("Select an order!");
        }
        else{
          debugger;
            let curorder=this.state.order;
            //alert(JSON.stringify(this.props.lead) );0.04
            curorder.LeadId=this.props.lead.LeadId;
            this.setState({order:curorder});
           // alert(JSON.stringify(this.state.order) );
            let url="api/order";
            this.service.post(url,curorder)
            .then((responsedata)=>{
            //debugger;
            
                this.props.onSaveHandler();
            })
            .catch((err) => { 
            console.log(err);
            });
        }
        
      }
     /*
    onSelectionChanged = useCallback(() => {
      debugger;
      const selectedRows = gridRef.current.api.getSelectedRows();      
       var selectedid= selectedRows.length === 1 ? selectedRows[0].id : '';
       alert("Selected row id: "+selectedid);
    }, []);*/
    GetProduct = (filter) => {
        let url="api/product?$select=ProductId,Name,code,UnitPrice,UnitPriceFull&$filter="+filter;
        this.service.get(url)
        .then((responsedata)=>{
          //debugger;
          this.setState({rowData:responsedata});
            
        })
        .catch((err) => { 
          //console.log(this.state.rowData);
        });
        
      }
      onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    
        
    
        
      };
    
      onSelectionChanged = () => {
        var selectedRows = this.gridApi.getSelectedRows()[0];
            //document.querySelector('#selectedRows').innerHTML = selectedRows.length === 1 ? selectedRows[0].athlete : '';
        //alert(selectedRows.Name);
        debugger;
        let lowercaseProductId = selectedRows.ProductId.toLowerCase(); //To convert Lower Case
        let IsOther=false;
        if(lowercaseProductId==="2FA0F3D2-DFA6-4FF3-A260-EB8C86AC575E".toLowerCase()){
          IsOther=true;
        }
        let curorder=this.state.order;
        curorder.ProductId=selectedRows.ProductId; 
        this.setState({order:curorder,IsOther:IsOther});
      };
    
    
    render(){
    return (
      <form onSubmit={this.handleSubmit}>
<hr/>
         <Row>
            <Col xl={6}>
                
                    <input 
                        type="text" 
                        name="productcode" 
                        placeholder="Code or Product Name"
                        onChange={this.handleProductCodeChange}
                    />
                
            
            </Col>
           
            <Col xl={6}> 
            <div>
                <select  onChange={this.handleCategoryChange}>
                <option value={"0"}>Select Category</option>
                    {this.state.categories.map(category=>(
                        <option value={category.stringmapid}>{category.name}</option>                   
                                     
                    ))}
                    
                </select></div>
            </Col>
            </Row>
            <Row>
            <Col  >
            
    <div className="ag-theme-alpine" style={{height: 400, width: 'auto'}}>
        <AgGridReact
            
            rowData={this.state.rowData}
            columnDefs={this.state.columnDefs}
            rowSelection='single'
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}>
        </AgGridReact>
    </div>   
            </Col>
        </Row>
        { this.state.IsOther  && (<>
        <Row>
            
            <Col xl={4}> 
            <input type="text" placeholder={"Enter Remark"} onChange={this.handleRemarkChange}/>
            
            </Col>
            <Col xl={4} ><div>
            <input type="number" placeholder={"Enter Rate"} onChange={this.handleRateChange}/>
            </div>               
            </Col>
            <Col xl={4} style={{textAlign:"left"}}>               
                    
            </Col>
        </Row>
        </>
    )}
            <Row>
            
            <Col xl={4}> 
           
            <div>
                <select  onChange={this.handleProductSizeChange}>
                <option value={"0"}>Select Size</option>
                {this.state.productsizes.map(item=>(
                        <option value={item.stringmapid}>{item.name}</option>
                ))}
                </select></div>
            </Col>
            <Col xl={4} ><div>
            <input type="number" placeholder={"Enter Qty"} onChange={this.handleQtyChange}/>
            </div>               
            </Col>
            <Col xl={4} style={{textAlign:"left"}}>               
                    <input type="submit"  value="Add"/>                
            </Col>
        </Row>
    </form>
    )
  }
}
