import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from '../../App';
import Order from '../../pages/order/order';
import OrderCheckItem from '../../pages/order/OrderCheckItem';
import OrderList from '../../pages/order/orderlist';
import Container from '../../pages/container';
import Home from '../../pages/home';
import { Login } from '../../pages/user/login';

export default function AppRoute() {

  function changeHandler(value){
   /* this.setState({
        value: value
    });*/
   
  }
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>    
            <Route index element={<Home />} />         
            <Route path="page" element={<Container />} >    
              <Route index element={<Home />} />
              <Route path="order"  element={<Order />} > </Route>           
              <Route  path="OrderList" element={<OrderList />} />
              <Route  path="OrderCheckItem" element={<OrderCheckItem />} />          
              
            </Route>  
            <Route path="order"  element={<Order />} > </Route>   
        </Route>
        
        <Route path="login"  element={<Login />} />
        <Route path="*" element={<main style={{padding:"2rem"}}>
          <p>There's Nothing here</p>
        </main>} />
      </Routes>
    </BrowserRouter>
    
  

  );
}


