
    import React  from 'react';
    
        export  function Login() {
          
          return (
            <div style={{margin:100}}>
                <h1>This is login page.</h1>           
            </div>
          );
        }
        
        
        