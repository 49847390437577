import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';

function AppCard(props) {
    
  return (
   <div style={{float: "left",margin:10}}>
        <Card style={{ width: '18rem',textAlign:"center" }}>
            <Card.Body>
                <Card.Title>
                <Link to={props.route}>{props.title}</Link>
                </Card.Title>
            </Card.Body>
            </Card>
            </div>
    
  );
}

export default AppCard;