
    import React  from 'react';
    
    import AppCard from '../shared/card';
    
        export default function Home() {
          const appscard=
          [
            {route:"/",title:"Home"},
            {route:"/page/order",title:"Order"},
            {route:"/page/OrderCheckItem",title:"CheckItem"},
            {route:"/page/OrderList",title:"OrderList"},
            {route:"/",title:"Home"},
            {route:"/",title:"Home"},
            {route:"/",title:"Home"},
            {route:"/",title:"Home"},
            {route:"/",title:"Home"}
          ];
          const appcditearm=appscard.map((app)=>{
              return <AppCard route={app.route} title={app.title}/>
          });
          return (
            <div style={{margin:100}}>
                {appcditearm}
            </div>
          );
        }
        
        
        