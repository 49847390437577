import React  from 'react';
import { Outlet } from "react-router-dom";
import Header from './shared/header';

export default function App() {
  return ( <div>
   <div className="row" style={{margin:12}}>
      <div className="col-sm-12">
      <Header/>     
      </div>
    </div>
    <div className="row" style={{margin:12}}>
      <div className="col-sm-12">
        <Outlet/>
      </div>
    </div>
  </div>
  );
}


