import React  from 'react';
import LeftNavigation from '../shared/leftnavigation';
import { Outlet } from "react-router-dom";

export default function Container() {
  return ( <div>
    
     <div className="row" style={{margin:12}}>
     <div className="col-sm-1">
     <LeftNavigation/>
     </div>
       <div className="col-sm-11">
         <Outlet/>
       </div>
     </div>
   </div>
  );
}


