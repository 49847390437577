import React, { useCallback, useMemo, useRef, useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { AgGridReact } from 'ag-grid-react';
import { ClientService } from '../../services/service';

export default class OrderCheckItemextends extends React.Component{
  constructor(props) {
      super(props);
      this.service=new ClientService();
      
      this.checkListItemcolumnDefs=[{headerName:'Index',valueGetter:'node.rowIndex+1',width:75},
          { field: 'OrderId', width:80,hide:true },
          { field: 'ProductIdName',headerName:'Item', sortable: true,width:150 },
          { field: 'SizeIdName', headerName:'Size',sortable: true,width:80 },
          { field: 'Qty' ,width:70},
          { field: 'Rate' ,width:100},
          { field: 'Amount',width:100}
      ];
      this.state=
      {
        OrderId:"",
        //netPayment:this.props.checkItemAmount,
        lead:{
                LeadId:"",
                discountedamt:0
            }
      }
      
      this.onSelectionChanged=this.onSelectionChanged.bind(this);
      this.handlePaymentonClick=this.handlePaymentonClick.bind(this);
      this.handleDiscountonChange=this.handleDiscountonChange.bind(this);
      this.onCacelClick=this.onCacelClick.bind(this);
      this.onGridReady=this.onGridReady.bind(this);
    }
  
    handleDiscountonChange(e){
      //let netPayment=this.props.checkItemAmount-e.target.value;
      this.setState({lead:{discountedamt:e.target.value}});

    }
    handlePaymentonClick(){
      this.setState({lead:{LeadId:this.props.lead.LeadId}});
      let url="api/lead/"+this.props.lead.LeadId;
      this.service.put(url,this.state.lead)
      .then((responsedata)=>{
      //debugger;
      this.props.getSalesList();
         
      })
      .catch((err) => { 
      console.log(err);
      });
    }
    onSelectionChanged(){
      debugger;
      //const selectedRows = this.gridApi.current.api.getSelectedRows();
      var selectedRows = this.gridApi.getSelectedRows();
       var selectedid= selectedRows.length === 1 ? selectedRows[0].OrderId : '';
       this.setState({OrderId:selectedid});
      // alert("Selected row id: "+selectedid);
    }
   onCacelClick(){
    
    let url="api/order/"+this.state.OrderId;
    //alert(url);
    this.service.delete(url)
    .then((responsedata)=>{
      this.props.onSaveHandler();
        
    })
    .catch((err) => { 
      //console.log(this.state.rowData);
    });
   }
   onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };
   render(){
    
  return (
    <div>
     <h6>Check item: {this.props.lead==null?"":this.props.lead.Name}</h6>
    <div className="ag-theme-alpine" style={{height: 430, width: 'auto'}}>
        <AgGridReact            
            rowData={this.props.checkItemRowData}
            columnDefs={this.checkListItemcolumnDefs}
            rowSelection='single'
            onSelectionChanged={this.onSelectionChanged}
            onGridReady={this.onGridReady}>
        </AgGridReact>
    </div>
    <div style={{"float":"right"}}><h6>Amt: {this.props.checkItemAmount}, Net Amt:{(this.props.checkItemAmount-(this.state.lead==null?0:this.state.lead.discountedamt))}</h6></div>
    <input type="text" size={"5"} placeholder={"Discount"} onChange={this.handleDiscountonChange}/>&nbsp;
    <Button as="a" variant="secondary" onClick={this.handlePaymentonClick}>Payment</Button>  &nbsp;
    
    <Button as="a" variant="secondary" onClick={this.onCacelClick}>Cancel</Button>
    </div>

  );
}
}
