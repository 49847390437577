  
  import React, { useCallback,  useRef, useState } from 'react';
  import { render } from 'react-dom';
  import { AgGridReact } from 'ag-grid-react';

export   default function  OrderList(props) { 
 
  const gridRef = useRef();
  const columnDefs=[
    { field: 'LeadId', hide:true},
    { field: 'Name',headerName:'Order List' , width:200}
  ];
const onSelectionChanged =() => {
  debugger;
  const selectedRows = gridRef.current.api.getSelectedRows()[0];
  
  // var selectedid= selectedRows;//.length === 1 ? selectedRows  : '';
   //alert("Selected row id: "+selectedRows.LeadId);
   props.changeHandler(selectedRows);
}

  return (  
    <div>  
      <hr/>
        <div className="ag-theme-alpine" style={{height: 450, width: 'auto'}}>
        <AgGridReact
            ref={gridRef}
            rowData={props.rowData}
            columnDefs={columnDefs}
            rowSelection='single'
            onSelectionChanged={onSelectionChanged}>
        </AgGridReact>
        </div>
    </div>  
  );  
}  
 
