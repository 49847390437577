
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OrderList from './orderlist';
import OrderCheckItem from './OrderCheckItem';
import OrderCreate from './OrderCreate';
import { ClientService } from '../../services/service';
import { Button, Navbar,NavbarBrand } from 'react-bootstrap';
import CreateOrderModal from './CreateOrderModal';

export default class Order extends React.Component {
  constructor(props) {
  
    super(props);
    this.service=new ClientService()
    
    this.state={
      show:false,
      rowData:[],      
      checkItemRowData:[],      
      lead:{Name:"",LeadId:""},
      checkItemAmount:0.0
    }
    this.getSalesList= this.getSalesList.bind(this);
    this.changeHandler=this.changeHandler.bind(this);
    this.GetCheckItemList=this.GetCheckItemList.bind(this);
    this.onSaveHandler=this.onSaveHandler.bind(this);
  }
  componentDidMount()
  {
    this.getSalesList();
  }
  GetCheckItemList = (LeadId) => {
    let url="api/order?$select=orderId,leadId,productId,ProductIdName,SizeIdName,qty,rate,amount&$filter=leadId eq "+LeadId+"&$orderby=createdon desc";
    this.service.get(url)
    .then((responsedata)=>{
     // debugger;
      let total=0;
      responsedata.map((item,index)=>{
//debugger;
      total=total+item.Amount;
    });
      this.setState({checkItemRowData:responsedata,checkItemAmount:total});
        
    })
    .catch((err) => { 
      console.log(err);
    });
    
  }
  getSalesList = () => {
    //this.setState({color: "blue"});
    this.service.get("/api/lead?$select=name,LeadId&$filter=statuscode eq 1&$orderby=EntryDate desc")
      .then((responsedata)=>{
        //debugger;
        if(!(responsedata===null || responsedata==='undefined')){
        this.setState({rowData:responsedata,lead:responsedata[0]});
        }
      })
      .catch((err) => { 
        console.log(this.state.rowData);
      });
  }
  
  changeHandler(obj){
   /* this.setState({
        value: value
    });*/
    debugger;
    //this.state.LeadId=obj.LeadId;
    //alert("OK: "+obj.LeadId);
    this.setState({lead:obj});
    
    this.GetCheckItemList(obj.LeadId);
  }
  onSaveHandler(){
     this.GetCheckItemList(this.state.lead.LeadId);
   }
  
  render(){
  
    return (
    <div>
      <Container fluid>

        <Row>
            <Col xs={2} style={{backgroundColor: "lightblue"}}>
              <div>
                <OrderList changeHandler ={this.changeHandler} rowData={this.state.rowData} />
              </div>
            </Col>
            <Col xs={5} style={{backgroundColor: "lightblue"}}>
              <OrderCheckItem checkItemRowData={this.state.checkItemRowData} 
              checkItemAmount={this.state.checkItemAmount}
              lead={this.state.lead}
              onSaveHandler ={this.onSaveHandler} 
              getSalesList={this.getSalesList}/>
            </Col>
            <Col xs={5} style={{backgroundColor: "lightblue"}}>
                  <OrderCreate onSaveHandler ={this.onSaveHandler}  lead={this.state.lead}/>
            </Col>
          </Row>
          <CreateOrderModal onClose={()=>{this.setState({show:false});this.getSalesList();}} show={this.state.show}  lead={this.state.lead}/>
          <div>
          <Button variant="dark"  onClick={()=>this.setState({show:true})} >New Order</Button>
          </div>
        </Container >
      </div>

      );
    }
}


