import React  from 'react';
import { Link } from "react-router-dom";

export default function LeftNavigation() {
    return (
  
 <div>
   
    <ul className="nav nav-pills flex-column">
      <li className="nav-item">
      <Link className="nav-link" to="/page/order">Order</Link>
      </li>
      <li className="nav-item">
      <Link className="nav-link" to="/page/OrderCheckItem">Item</Link>
      </li>
      
      <li className="nav-item">
      <Link className="nav-link" to="/page/OrderList">OrderList</Link>
      </li>
    </ul>
    <hr className="d-sm-none"/>
  </div>
    
      
  
  
    
  
    );
  }