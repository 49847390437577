import React from 'react';
import {Button,CloseButton,Card,Form} from 'react-bootstrap';
import '../../App.css';
import { ClientService } from '../../services/service';

export default class CreateOrderModal extends React.Component{
    constructor(props) {
        super(props);
        this.service=new ClientService();    
        this.state={
            sittingTables:[{stringmapid:"",name:""}],
            lead:{name:""}
        }
        this.getSittingTables=this.getSittingTables.bind(this);
        this.handleSittingTablesChange=this.handleSittingTablesChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
}
componentDidMount(){
    this.getSittingTables();
}
handleSittingTablesChange(evnt)
{
    debugger;
    //const value = evnt.target.value;
    const value = evnt.target.selectedOptions[0].text;
        //alert(value);
        let curlead=this.state.lead;
        curlead.name=value;   
           
        this.setState({lead:curlead});
}
getSittingTables()
    {

        debugger;
        let url="api/stringmap?$select=stringmapid,name&$filter=statecode eq true and type eq 'dyningtable' and objectname eq 'order' and attribute eq 'TableId'&$orderby=displayorder";
        //alert(url);
        this.service.get(url)
        .then((responsedata)=>{
          //debugger;
          this.setState({sittingTables:responsedata});
         // alert(JSON.stringify(responsedata));  
        })
        .catch((err) => { 
          //console.log(this.state.rowData);
        });
    }
    handleSubmit = (event) => {
        event.preventDefault();
        //if(this.props.lead==null || this.props.lead.LeadId===null || this.props.lead.LeadId==="" || this.props.lead.LeadId==='undefined'){
            if(this.state.lead==null){
            alert("Select an order!");
        }
        else{
          debugger;
           // alert(JSON.stringify(this.state.order) );
            let url="api/lead";
            this.service.post(url,this.state.lead)
            .then((responsedata)=>{
            //debugger;
            this.props.onClose();
               
            })
            .catch((err) => { 
            console.log(err);
            });
        }
        
      }


render(){
    //  this.checkModalEnable();
    if(!this.props.show){
        return null;
    }
return(
    
    <div className="modal">
        <div className="modal-content">
            <Card>
                <Card.Header>
                <b>Enter Order Detail</b>
                    <div style={{float:"right"}}>
                        <CloseButton onClick={this.props.onClose}/>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Select Sitting Place</Form.Label>

                            <Form.Select onChange={this.handleSittingTablesChange}>
                                <option>select sitting table</option>
                                {this.state.sittingTables.map(item=>(
                                        <option value={item.stringmapid}>{item.name}</option>
                                ))}
                                
                            </Form.Select>                         
                        </Form.Group>

                        
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                    
                </Card.Body>
            </Card>
        </div>
    </div>
    
)
}
}